.page {
  display: block;
  padding-top: 92px;
}

// X-Small devices (portrait phones, less than 430px)
@media (max-width: 429.98px) {
  .page {
    padding-top: 40%;
  }
}
