.work-header {
  width: 100%;
  top: 0;
  background: #00a19c;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 999;
  a {
    text-decoration: none;
    line-height: 15px;
  }
  .work-logo-txt {
    padding-left: 48px;
    padding-top: 8px;
    .work_txt_head {
      color: #f5f5f5;
      font-size: 66px;
      font-weight: 600;
      word-wrap: break-word;
    }
    .work_txt_det {
      color: #e0f3f3;
      font-size: 12px;
      font-weight: 500;
      word-wrap: break-word;
    }
  }
}

.head-text {
  color: white;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
}

.navbar-text img {
  max-height: 30px;
  width: auto;
  vertical-align: middle;
}

.heder-user-text {
  color: white;
  font-size: 14px;
}

.avtar_img {
  max-height: 48px !important;
}

a {
  text-decoration: none;
}
