
body {
  background-color: #F2F1F9 !important;
}

.ant-btn-primary {
  background-color: #00908C;
}

.ant-btn-primary:hover {
  background-color: #248986;
}

.ant-typography-collapse,
.ant-typography-expand {
  text-decoration: underline !important;
}

.module_heading {
  font-weight: bold;
  color: #00908C;
}

.ant-btn >span:not(:only-child) {
  display: inline-flex;
  align-self: center !important;
}